@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url(https://fonts.googleapis.com/css2?family=KoHo:wght@400;500;600;700;800;900&amp;display=fallback);     */

@import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body::-webkit-scrollbar {
    display: none;
}

dialog::backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(71, 85, 105, 0.6);
}

body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


::-webkit-scrollbar {
    display: none;
  }
  
  .f-s-r {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
  
  .f-s-m {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  
  .f-s-sb {
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
  }
  
  .f-p1-r {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  
  .f-p1-m {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  
  .f-p1-sb {
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
  }
  
  .f-p2-r {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  
  .f-p2-m {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  
  .f-p2-sb {
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
  }
  
  .f-h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 900;
  }
  
  .f-h2 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;
  }
  
  .f-h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
  }
  
  .f-h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
  }
  
  .f-h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }